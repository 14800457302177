<template>
    <div>
        <div class="px-0 py-6 bg-white rounded-2xl shadow-lg ml-4">

            <div class="flex justify-start">
                <b class="text-left text-2xl mx-2 pb-6 pl-8 text-blue">Detalle informe anual</b>
            </div>
            
            <Accordion key="astep1" class="px-8" :open="openAccordions.detail" :disable="false"
                header="Detalles del informe anual">

                <cero_detail :annual_report="annual_report" :disabledElements="true"></cero_detail>
        
            </Accordion>

            <div class="flex justify-start">
                <b class="text-left text-xl mx-2 pt-8 pb-4 px-8 text-blue">Detalles de las funciones</b>
            </div>
            <hr />

            <a-list
                class="mx-8 px-4 text-left demo-loadmore-list"
                item-layout="horizontal"
                :data-source="data"
            >
                <a-list-item slot="renderItem" slot-scope="item, index">
                <a slot="actions">
                    <a-button 
                        @click="goToStepDetail(item.step)"
                        class="col-span-2 mx-8"
                        type="default"
                        shape="round"
                        size="large">
                        <b class="inline mx-4 font-bold text-xs">Ver función</b>
                    </a-button>
                </a>
                <a-list-item-meta>
                <a slot="title" class="text-lg"><b>{{ item.title }}</b></a>
                </a-list-item-meta>
                </a-list-item>
            </a-list>
            

            <div class="w-full grid grid grid-cols-2 gap-6 justify-end items-end p-8">
                <a-button 
                    class="col-span-2 mx-8"
                    type="default"
                    shape="round"
                    @click.native="goToReportList"
                    size="large">
                    <b class="inline mx-4 font-bold text-xs">VOLVER</b>
                </a-button>
            </div>

        </div>
    </div>
</template>
<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import cero_detail from "@/feature/annual_report/presentation/views/components/step0_detail/cero_detail";
import AnnualReportServices from "@/feature/annual_report/data/services/annual_report_services";
import ErrorMessaje from "@/core/error/error_messaje";


export default {
    components: {
        Accordion,
        cero_detail
    },
    data(){
        return {
            annual_report:{},
            openAccordions:{
                detail: true
            },
            data: [
                { step:1, title: '1. De la función de atención y resolución de quejas' },
                { step:2, title: '2. De la función de atención al consumidor financiero' },
                { step:3, title: '3. De la función de conciliación' },
                { step:4, title: '4. Información adicional' },
            ]
        }
    },
    async created() {
        const document_id = this.$route.params.id;
        let response = await AnnualReportServices.getAnualReport(document_id);
        this.annual_report = response.data[document_id]
        this.annual_report.id = document_id;
    },
    methods:{
        goToStepDetail(step){
            console.log(step)
            this.$router.push({ 'name': 'annual-report-detail-step', 
                params: { id: this.annual_report.id, showStepDetail: step },}
            )
        },
        goToReportList() {
            this.$router.push({ 'name': 'annual-report-list' }
            )
        },
    }
}

</script>